import https from "./https"
import store from "@/store"

const organization = store.state.general.organization;

const overviewApi = {
  getLineData() {
    return https.get(`admin/organizations/${organization.id}/overview/line-data`)
  },
  getOaData() {
    return https.get(`admin/organizations/${organization.id}/overview/oa-data`)
  },
  getSmsData() {
    return https.get(`admin/organizations/${organization.id}/overview/sms-data`)
  },
}

export default overviewApi
